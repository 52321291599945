@import '~antd/dist/antd.css';

.chat-list {
  height: 100%;
  padding-bottom: 44px;
}

.edit-thumbnail-dropdown {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 6px 8px;
}

.menu-upload {
  display: block;
}

.menu-upload .ant-upload {
  display: block;
}
